import * as Sentry from '@sentry/browser';

export const logError = (error, context) => {
  let text = '';

  if (typeof error !== 'string') {
    if (error.body) {
      const response = error.body;

      text += response.message + '<br>';

      if (
        response.errors &&
                Object.keys(response.errors).length > 0
      ) {
        const errors = Object.entries(response.errors);
        errors.forEach(([
          , messages,
        ]) => {
          messages.forEach(message => {
            text += '-' + message + '<br>';
          });
        });
      }
    }
  } else {
    text = error;
  }

  Sentry.captureException(new Error(text));

  if (context) {
    context.$root.$emit('error:show', text);
  } else {
    Bus.$emit('error:show', text);
  }
};
