import validate from 'validate.js';

export const passwordRequirements = (passwordString, additionalRules = []) => {
  return [
    {
      hasError: () => {
        return validate.single(passwordString, {
          length: {
            minimum: 6, 
          },
        });
      },
      message: 'at least 6 characters',
    },
    {
      hasError: () => {
        return validate.single(passwordString, {
          format: {
            pattern: /^(?=.*[a-z]).+$/, 
          },
        });
      },
      message: 'one lowercased character',
    },
    {
      hasError: () => {
        return validate.single(passwordString, {
          format: {
            pattern: /^(?=.*[A-Z]).+$/, 
          },
        });
      },
      message: 'one uppercased character',
    },
    {
      hasError: () => {
        return validate.single(passwordString, {
          format: {
            pattern: /^(?=.*\d).+$/, 
          },
        });
      },
      message: 'one digit',
    },
    {
      hasError: () => {
        return validate.single(passwordString, {
          format: {
            pattern: /^(?=.*(_|[^\w])).+$/, 
          },
        });
      },
      message: 'one special character',
    },
    ...additionalRules,
  ];
};

export const emailRequirements = (emailString, additionalRules = []) => {
  return [
    {
      hasError: () => {
        return validate.single(emailString, {
          email: true, 
        });
      },
      message: 'should be a valid email',
    },
    ...additionalRules,
  ];
};

export const fullNameRequirements = (name, additionalRules = []) => {
  return [
    {
      hasError: () => {
        return validate.single(name, {
          length: {
            minimum: 3, 
          },
        });
      },
      message: 'at least 3 characters',
    },
    ...additionalRules,
  ];
};

export const userNameRequirements = (userName, userNamesList, additionalRules = []) => {
  return [
    {
      hasError: () => {
        return userNamesList.includes(userName);
      },
      message: 'username should be unique',
    },
    ...additionalRules,
  ];
};

export const hexColorRequirements = (colorString, additionalRules = []) => {
  return [
    {
      hasError: () => {
        return !(/^[A-f0-9]{3,6}$/.test(colorString));
      },
      message: 'color has to be a valid hex color string',
    },
    ...additionalRules,
  ];
};

export const getErrorMessageBySlug = (slug) => {
  const messages = {
    'validation.mimetypes': 'Invalid type of file',
  };
  return messages[slug] || slug;
};
