require('./generals.js');
import Vue from 'vue';
import VueResource from 'vue-resource';
Vue.use(VueResource);

if (document.getElementById('csrf-token')) {
  Vue.http.headers.common['X-CSRF-TOKEN'] = document.getElementById('csrf-token')
    .getAttribute('content');
}

window.Bus = new Vue();

import Login from './auth/login/login.vue';
import Email from './auth/password/request-reset.vue';
import Reset from './auth/password/reset-password.vue';
import Toaster from '@shared/components/toaster.vue';
import Modal from '@shared/components/modal/index.vue';

new Vue({
  el: '#auth-app',
  components: {
    Login,
    Email,
    Reset,
    Toaster,
    Modal,
  },

  methods: {
    displayError(error) {
      this.$refs.toaster.toast(error);
    },
  },

  created() {
    this.$on('error:show', this.displayError);
  },
});
