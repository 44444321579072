<script>
import FormInput from '@shared/components/general/form-input.vue';

export default{
  props: [
    'csrfToken',
    'errorMessages',
    'token',
  ],

  components: {
    FormInput,
  },

  data: () => ({
    confirmPasswordValue: '',
    passwordValue: '',
    emailValue: '',
  }),

  computed: {
    isDisabled() {
      return !this.emailValue ||
                !this.passwordValue ||
                !this.confirmPasswordValue ||
                this.$validate.hasErrors();
    },
  },

  methods: {
    confirmPasswordRequirements(string) {
      return [
        {
          hasError: () => {
            return string.length === 0 ||
                        this.passwordValue !== string;
          },
          message: 'equal to new password',
        },
      ];
    },

    onConfirmInput(text) {
      this.confirmPasswordValue = text;
    },

    onPasswordInput(text) {
      this.passwordValue = text;
    },

    onEmailInput(text) {
      this.emailValue = text;
    },

    reset() {
      return !this.$validate.hasErrors() && this.$refs.form.submit();
    },
  },
};
</script>
<template>
  <div class="auth-form-container">
    <form
      method="POST"
      action="/password/reset"
      class="auth-form card"
      ref="form"
      @submit.prevent="reset">
      <h1 class="card__header">Enter new Password</h1>

      <div class="card__content">
        <input
          type="hidden"
          name="_token"
          :value="csrfToken">
        <input
          type="hidden"
          name="token"
          :value="token">

        <form-input
          :clear-on-enter="false"
          :placeholder="'E-Mail'"
          name="email"
          @input="onEmailInput"
          :label="'Email address'">
        </form-input>

        <form-input
          :clear-on-enter="false"
          :label="'New Password'"
          name="password"
          @input="onPasswordInput"
          :placeholder="'New Password'"
          :type="'password'">
        </form-input>

        <form-input
          :requirements="confirmPasswordRequirements"
          :clear-on-enter="false"
          :label="'Confirm Password'"
          name="password_confirmation"
          @input="onConfirmInput"
          :placeholder="'Confirm Password'"
          :type="'password'">
        </form-input>

        <button
          class="button button_primary button_full-width"
          type="submit"
          :disabled="isDisabled">
          Set new password
        </button>
      </div>
    </form>
  </div>
</template>
