export default {
  data: () => ({
    currentValue: '',
    isFocused: false,
    requirementsEl: (() => {
      const container = document.createElement('div');
      container.className = 'form-field__requirement-list';
      return container;
    })(),
  }),

  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },

  watch: {
    _requirements() {
      this.updateRequirementsEl();
    },
    currentValue() {
      this.updateRequirementsEl();
    },
  },

  mounted() {
    this.updateRequirementsEl();
  },

  computed: {
    hasOption() {
      return this.optionText && this.optionText.length > 0;
    },

    hasLabel() {
      return this.label && this.label.length > 0;
    },

    inputValue() {
      return this.currentValue ? this.currentValue : this.value || '';
    },

    hasErrorClass() {
      return this.inputValue.length > 0 &&
                this._requirements.filter(rule => {
                  return rule.hasError();
                }).length > 0;
    },
  },

  methods: {
    updateRequirementsEl() {
      // Updating the HTML like this makes sure we do not have to wait for a child
      // component to finish mounting before we can show the v-tooltip

      this.requirementsEl.innerHTML = '';
      for (let i = 0; i < this._requirements.length; i++) {
        const rule = this._requirements[i];
        const p = document.createElement('p');
        p.className = 'form-field__requirement';
        if (rule.hasError()) {
          p.classList.add('form-field__requirement_has-error');
        }

        const icon = document.createElement('span');
        icon.className = 'icon icon_indicator';

        p.appendChild(icon);
        p.innerHTML = p.innerHTML + rule.message;

        this.requirementsEl.appendChild(p);
      }
    },

    onInput(e) {
      const text = e.target ? e.target.value : '';
      this.currentValue = text;
      this.$emit('input', text);
      return this.validate();
    },

    validate() {
      if (this._requirements.length > 0) {
        this.$validate.errors[this.name] = this.hasError;
      }
    },

    onBlur() {
      this.isFocused = false;
      return this.$emit('blur', this.currentValue);
    },

    onFocus() {
      this.isFocused = true;
      return this.$emit('focus', this.currentValue);
    },

    onEnter(e) {
      if (this.clearOnEnter) {
        this.clearInput();
      }

      const text = e.target ? e.target.value : '';
      this.$emit('enter', text);
    },

    focus() {
      this.$refs.input.focus();
    },

    select() {
      this.$refs.input.select();
    },

    blur() {
      this.$refs.input.blur();
    },

    clearInput() {
      this.currentValue = '';
    },
  },
};
