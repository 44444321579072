<script>
export default {};
</script>

<template>
  <div class="icon-link">
    <icon name="round"></icon>
    <icon name="arrow-long-up"></icon>
  </div>
</template>
