<script>
const ERROR_TYPE = 'error';
const SUCCESS_TYPE = 'success';

const toasterTypes = [
  ERROR_TYPE,
  SUCCESS_TYPE,
];

/**
 * This is a general toaster. Could be used to display uncaught errors or success messages.
 * By default behaves like error toaster
 */
export default {
  data: () => ({
    messages: [],
  }),

  methods: {
    /**
         * Handle a message
         * @public
         * @param {object,string} [message] By default the error object that we need to handle. If nothing is passed, we revert to displaying GENERAL_FAILED from @zedach/brix-lang.
         * @param {string} [type] The type of the toaster, by default its 'error'; if type !== 'error' will work to show strings only; if type === 'success' will be styled as success toaster
         */
    toast(message, type) {
      type = type || ERROR_TYPE;
      this.handleMessage(message, type);
    },

    closeMessage(message) {
      this.messages.splice(this.messages.indexOf(message), 1);
    },

    handleMessage(message, type) {
      if (
        type !== ERROR_TYPE &&
                typeof message === 'string' &&
                toasterTypes.includes(type)
      ) {
        this.addMessage({
          text: message,
          type,
        });
        return;
      }

      if (message.code === 422) {
        const fields = Object.keys(message.errors);
        for (const field of fields) {
          for (const text of message.errors[field]) {
            this.addMessage({
              text, 
            });
          }
        }
      } else if (message.code && message.text) {
        this.addMessage({
          text: message.text, 
        });
      } else if (typeof message === 'string') {
        this.addMessage({
          text: message, 
        });
      } else {
        this.addMessage({
          text: 'Something went wrong. Please try again',
        });
      }
    },

    addMessage(data) {
      const message = {
        ...data, 
      };
      message.id = +new Date();
      this.messages.push(message);
    },
  },
};
</script>

<template>
  <transition-group
    name="Toaster"
    tag="div"
    class="toaster-wrap"
    :class="{ 'toaster-wrap_hidden': messages.length === 0 }"
  >
    <div
      v-for="(message, index) in messages"
      class="toaster"
      :class="[message.type ? `toaster_${message.type}` : '']"
      :key="message.id"
      :style="{
        zIndex: index + 1,
        top: `${index * 12}px`,
      }"
    >
      <p class="fbx-marginal toaster__text" v-html="message.text"></p>
      <button
        type="button"
        @click.prevent="closeMessage(message)"
        class="toaster__close"
      >
        <icon name="close-delete" />
      </button>
    </div>
  </transition-group>
</template>
