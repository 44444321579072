if (window.$ === undefined || window.jQuery === undefined) {
  window.$ = window.jQuery = require('jquery');
}

import Vue from 'vue';
import VTooltip from 'v-tooltip';
import * as Sentry from '@sentry/browser';

if (window.sentryConfiguration) {
  Sentry.init({
    dsn: window.sentryConfiguration.dsn,
    environment: window.sentryConfiguration.environment,
  });
}

Vue.use(VTooltip);

Vue.config.productionTip = false;

import moment from 'moment';

Object.defineProperties(Vue.prototype, {
  $moment: {
    get: () => {
      return moment;
    },
  },
});

Vue.prototype.$validate = {
  errors: {},
  hasErrors: function() {
    let isError = false;
    for (const p in this.errors) {
      if (this.errors[p]) {
        isError = true;
      }
    }

    return isError;
  },
};

import Icon from '@shared/components/globals/icon.vue';
Vue.component('icon', Icon);

import IconLink from '@shared/components/globals/icon-link.vue';
Vue.component('icon-link', IconLink);
