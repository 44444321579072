<script>
import {
  passwordRequirements,
  emailRequirements,
} from '../../../utility/validation.js';
import FormInputMixin from '@shared/components/general/mixins/form-input.js';

export default {
  mixins: [
    FormInputMixin,
  ],

  data: () => ({
    // This is used so that with a "password" to "text" switch,
    // which happens when a user clicks "show" on a password field,
    // the right requirements are still validated
    type_: 'text',
  }),

  watch: {
    value(val) {
      if (!val) {
        this.clearInput();
      } else {
        this.currentValue = val;
      }
    },

    inputValue() {
      this.$nextTick(() => {
        this.validate();
      });
    },
  },

  props: {
    type: {
      type: String,
      default: 'text',
    },
    clearOnEnter: {
      type: Boolean,
      default: true,
    },
    optionText: {
      type: String,
      default: '',
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    requirements: {
      default: null,
    },
  },

  created() {
    this.type_ = this.type;
    this.currentValue = this.value;
    this.$nextTick(() => {
      this.validate();
    });
  },

  computed: {
    _requirements() {
      if (!this.isValidate) {
        return [];
      }

      if (this.requirements) {
        return this.requirements(this.inputValue);
      }

      if (this.type_ === 'email') {
        return emailRequirements(this.inputValue);
      } else if (this.type_ === 'password') {
        return passwordRequirements(this.inputValue);
      }

      return [];
    },

    isShowAlert() {
      return this.inputValue.length > 0 &&
                this.isValidate && this.type_ !== 'password';
    },

    hasError() {
      return this.inputValue.length === 0 ||
                this._requirements.filter(rule => {
                  return rule.hasError();
                }).length > 0;
    },

    showRequirements() {
      if (!this.isFocused || this._requirements.length === 0) {
        return false;
      }

      if (this.type !== 'password') {
        return this.hasErrorClass;
      }

      return true;
    },
  },
};
</script>

<template>
  <div
    class="form-field"
    :class="{
      'form-field_disabled': disabled,
      'form-field_has-error': hasErrorClass,
      'form-field_has-icon': !!$slots.icon || !!$scopedSlots.icon,
    }">
    <slot name="label">
      <label v-if="hasLabel" class="form-field__label">
        {{ label }}
      </label>
    </slot>

    <div class="form-field__item">
      <input
        ref="input"
        v-tooltip.right="{
          content: requirementsEl,
          trigger: 'manual',
          show: showRequirements,
        }"
        class="input"
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @keyup.enter="onEnter"
        @click="$emit('click')"
        :disabled="disabled"
        :name="name"
        :value="inputValue"/>

      <a
        href="#"
        v-if="hasOption"
        @click.prevent="$emit('option-click')"
        class="form-field__option">
        {{ optionText }}
      </a>

      <icon
        class="form-field__alert"
        v-if="_requirements.length > 0 && isShowAlert"
        :name="hasErrorClass ? 'round-alert' : 'round-success'">
      </icon>

      <slot name="icon" />
    </div>
  </div>
</template>
