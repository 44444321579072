<script>
export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      default: 'trash',
    },
    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    style() {
      return {
        color: this.color ? this.color : false,
      };
    },
  },
};
</script>

<template>
  <span :class="'icon icon_' + name.toLowerCase()" :style="style"></span>
</template>
