<script>
import FormInput from '@shared/components/general/form-input.vue';
import {
  logError,
} from '@utility/log-error.js';

export default{
  props: [
    'csrfToken',
    'errorMessages',
  ],

  components: {
    FormInput,
  },

  data: () => ({
    isShowPassword: false,
  }),

  methods: {
    openInvitationModal() {
      Bus.$emit('modal:show', {
        view: () => import('@front/auth/request-invitation-modal.vue'),
      });
    },
  },

  mounted() {
    const errors = JSON.parse(this.errorMessages);
    if (Object.keys(errors).length > 0) {
      logError({
        body: {
          message: 'Sorry, something went wrong',
          errors,
        },
      }, this);
    }
  },
};
</script>
<template>
  <div class="auth-form-container">
    <form
      action="/login"
      method="POST"
      class="auth-form card">
      <h1 class="card__header">Log in to Realtrax</h1>
      <div class="card__content">
        <input
          type="hidden"
          name="_token"
          :value="csrfToken">

        <form-input
          :is-validate="false"
          :clear-on-enter="false"
          :placeholder="'Email'"
          name="email"
          :label="'Email'">
        </form-input>

        <form-input
          :is-validate="false"
          :clear-on-enter="false"
          name="password"
          :placeholder="'Password'"
          :optionText="isShowPassword ? 'hide' : 'show'"
          @option-click="isShowPassword = !isShowPassword"
          :type="isShowPassword ? 'text' : 'password'">

          <label slot="label">
            Password
            <a href="/password/reset" class="forgot-psw-link">
              Forgot?
            </a>
          </label>
        </form-input>

        <label class="form-field remember-me-field">
          <input
            class="remember-me-field__input"
            type="checkbox"
            name="remember"
            checked
          >
          Remember Me
        </label>

        <div class="form-field">
          <button class="button button_primary button_full-width" type="submit">
            Log in
          </button>
        </div>
      </div>

      <div class="card__footer">
        <p class="text_center margin-top_0">Don't have an account yet?</p>
        <p class="text_center"><a href="#" @click.prevent="openInvitationModal">Request invitation</a></p>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import '~@sass/shared/settings/_spacers.scss';

.remember-me-field {
  align-items: center;
  justify-content: flex-start;
  display: flex;

  &__input {
    margin-right: $spacer-small;
  }
}
</style>
