<script>
export default {
  data: () => ({
    view: '',
    payload: {},
  }),

  components: {
    'create-content': () => import('@flncy/create-content/index.vue'),
    'confirmation': () => import('./confirmation.vue'),
  },

  methods: {
    hide() {
      this.view = '';
      this.payload = {};
    },

    onModalShow({
      view, payload = {},
    }) {
      this.view = view;
      this.payload = payload;
    },

    keyDownHandler(e) {
      if (this.view) {
        if (e.keyCode === 27) {
          this.hide();
        }
      }
    },
  },

  created() {
    Bus.$on('modal:show', this.onModalShow);
    Bus.$on('modal:hide', this.hide);
    if (this.$router) {
      this.$router.afterEach(this.hide);
    }
    document.addEventListener('keydown', this.keyDownHandler);
  },

  beforeDestroy() {
    Bus.$off('modal:show', this.onModalShow);
    Bus.$off('modal:hide', this.hide);
    document.removeEventListener('keydown', this.keyDownHandler);
  },
};
</script>

<template>
  <div
    class="modal__wrapper"
    :class="{ 'modal__wrapper_visible': view }"
    @click.self="hide">
    <transition name="fade">
      <component
        class="modal"
        v-bind="{ payload }"
        :is="view"
        @close="hide"
        v-if="view"
      ></component>
    </transition>
  </div>
</template>
