var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-field",class:{
    'form-field_disabled': _vm.disabled,
    'form-field_has-error': _vm.hasErrorClass,
    'form-field_has-icon': !!_vm.$slots.icon || !!_vm.$scopedSlots.icon,
  }},[_vm._t("label",[(_vm.hasLabel)?_c('label',{staticClass:"form-field__label"},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-field__item"},[_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
        content: _vm.requirementsEl,
        trigger: 'manual',
        show: _vm.showRequirements,
      }),expression:"{\n        content: requirementsEl,\n        trigger: 'manual',\n        show: showRequirements,\n      }",modifiers:{"right":true}}],ref:"input",staticClass:"input",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"name":_vm.name},domProps:{"value":_vm.inputValue},on:{"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},"click":function($event){return _vm.$emit('click')}}}),_vm._v(" "),(_vm.hasOption)?_c('a',{staticClass:"form-field__option",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('option-click')}}},[_vm._v("\n      "+_vm._s(_vm.optionText)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm._requirements.length > 0 && _vm.isShowAlert)?_c('icon',{staticClass:"form-field__alert",attrs:{"name":_vm.hasErrorClass ? 'round-alert' : 'round-success'}}):_vm._e(),_vm._v(" "),_vm._t("icon")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }