<script>
import FormInput from '@shared/components/general/form-input.vue';

export default{
  props: [
    'csrfToken',
    'errorMessages',
    'successMessage',
  ],

  components: {
    FormInput,
  },

  data: () => ({
    inputValue: '',
  }),

  computed: {
    errors() {
      return JSON.parse(this.errorMessages);
    },
  },

  methods: {
    request() {
      return !this.$validate.errors.email &&
                this.$refs.form.submit();
    },

    onInput(text) {
      this.inputValue = text;
    },
  },
};
</script>
<template>
  <div class="auth-form-container">
    <div class="auth-form card" v-if="successMessage">
      <h1 class="card__header">You're almost done</h1>
      <div class="card__content">
        <h2 v-html="successMessage"></h2>
      </div>

      <div class="card__footer">
        <a
          href="/login"
          class="button button_primary button_full-width"
          style="margin-right: 15px;">
          Return to Login
        </a>
      </div>
    </div>

    <div class="auth-form card" v-else>
      <h1 class="card__header">Forgot your Password</h1>
      <form
        ref="form"
        action="/password/email"
        method="POST"
        class="card__content"
        @submit.prevent="request">
        <h2>
          We will send you an email in order to reset your password.
        </h2>
        <input
          type="hidden"
          name="_token"
          :value="csrfToken">

        <form-input
          @input="onInput"
          :clear-on-enter="false"
          :placeholder="'E-Mail'"
          type="email"
          name="email"
          ref="input"
          :label="'Email address'">
        </form-input>

        <div class="form-field">
          <button
            class="button button_primary button_full-width"
            type="submit"
            :disabled="inputValue.length === 0 || $validate.errors.email">
            Reset Password
          </button>
        </div>
      </form>

      <div class="card__footer text_center">
        <a href="/login" class="text-link">Cancel</a>
      </div>
    </div>
  </div>
</template>
